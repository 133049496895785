<!--
 * @Description: 维修服务单任务项
 * @Author: ChenXueLin
 * @Date: 2021-11-23 17:28:14
 * @LastEditTime: 2022-07-15 16:18:44
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div class="task-list-content">
      <detail-table-title>
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns>
          <el-button
            type="primary"
            v-if="
              (basicInfo.serviceStatusCode == 1 ||
                basicInfo.serviceStatusCode == 2) &&
                basicInfo.hasRight == 1
            "
            @click="handleDeal"
            >处理
          </el-button>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                placeholder="终端名称"
                title="终端名称"
                v-model="searchForm.sceneName"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                placeholder="场景类型"
                title="场景类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="operator">
              <el-input
                placeholder="操作人"
                title="操作人"
                v-model="searchForm.operator"
              ></el-input>
            </el-form-item>
            <el-form-item prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="操作时间"
                v-model="searchForm.updateTime"
                title="操作时间"
                :picker-options="pickerOptions('searchForm.updateTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="操作时间（始）"
                end-placeholder="操作时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </detail-table-title>
      <!-- 表格 -->
      <el-table
        border
        height="300px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :row-key="
          row => {
            return row.id;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
          :selectable="checkSelectable"
        ></el-table-column>
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'sceneName'">
              {{ row.show ? row[column.fieldName] : "" }}
            </span>
            <span v-else-if="column.fieldName === 'sceneType'">
              {{ row.show ? row[column.fieldName] : "" }}
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="180">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList(scope.row)"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 任务项 end -->

    <!-- 处理弹框  -->
    <el-dialog
      v-dialogDrag
      title="处理"
      :visible="dealWithVisible"
      width="450px"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="custom-dialog"
    >
      <div>
        <el-form
          :inline="true"
          label-width="120"
          :model="handleForm"
          :rules="handleFormRules"
          ref="handleForm"
        >
          <el-form-item label="诊断问题" prop="quesReason">
            <el-input
              placeholder="诊断问题"
              v-model="handleForm.quesReason"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="handleType">
            <el-radio-group v-model="handleForm.handleType" @change="change">
              <el-radio :label="1">已处理</el-radio>
              <el-radio :label="2">转指派</el-radio>
              <el-radio :label="3">转工单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="是否是部门"
            prop="isDept"
            v-if="handleForm.handleType == 2"
          >
            <el-radio-group v-model="handleForm.isDept">
              <el-radio :label="1">部门</el-radio>
              <el-radio :label="0">员工</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="handleForm.handleType == 2 && handleForm.isDept == 1"
            label="指派部门"
            prop="departmentId"
          >
            <!-- 部门下拉框 -->
            <e6-select
              v-model="handleForm.departmentId"
              :dataList="treeData"
              :filterable="true"
              :slotTemplate="'tree'"
              :multiple="false"
              :issingleleaf="false"
              ref="t_select"
            ></e6-select>
          </el-form-item>
          <el-form-item
            label="指派处理人"
            prop="solveRpid"
            v-if="handleForm.handleType == 2 && handleForm.isDept == 0"
          >
            <all-user-search
              v-model="handleForm.solveRpid"
              clear
              placeholder="指派处理人"
              title="指派处理人"
              :propsConfig="{
                id: 'employeeId',
                label: 'userName'
              }"
            >
            </all-user-search>
          </el-form-item>
          <el-form-item
            label="处理方案"
            prop="solution"
            v-if="handleForm.handleType == 1"
          >
            <el-input
              placeholder="处理方案"
              v-model="handleForm.solution"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="备注"
            prop="remark"
            v-if="handleForm.handleType == 1"
          >
            <el-input
              placeholder="备注"
              v-model="handleForm.remark"
              maxlength="100"
            ></el-input>
          </el-form-item>
          <div class="work-order" v-if="handleForm.handleType == 3">
            <!--  @click="toCreateTask" -->
            <div class="item">
              <img :src="iconUrl" alt="" />
            </div>
            <div class="til">维修任务单</div>
          </div>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
      </div>
    </el-dialog>
    <!-- 处理弹框 end -->
    <!-- 查看已安装设备弹框 -->
    <installed-devices
      :showEquipVisible="showEquipVisible"
      @handleClose="closeDialog"
      :sceneName="sceneName"
      :sceneTypeName="sceneTypeName"
      :vehicleId="vehicleId"
    ></installed-devices>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import InstalledDevices from "@/components/workOrderManage/installedDevices.vue";
import { repairColumn } from "../../tableColumnData";
import { getRepairService, handleRepairService } from "@/api";
import { printError } from "@/utils/util";
import taskDetail from "@/mixins/taskDetail";
import eventBus from "@/bus";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "repairService",
  data() {
    return {
      dialogLoading: false,
      tableData: [],
      vehicleId: "",
      searchForm: {
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        operator: "", //操作人
        updateTime: [], //操作时间
        updatedStartTime: "", //操作开始时间
        updatedEndTime: "", //操作结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: repairColumn,
      total: 0,
      selColumn: [], //勾选的任务项
      /******处理************/
      iconUrl: require("@/assets/images/weixiu.png"),
      handleForm: {
        quesReason: "", //诊断问题
        handleType: 1, //处理方式
        solution: "", //处理方案
        remark: "", //备注
        solveRpid: "", //指派处理人
        departmentId: [], //指派部门
        isDept: 1, //是否是部门
        transferType: "" //要转的工单类型
      },
      /*****查看已安装设备*****/
      sceneTypeName: "",
      sceneName: "",
      showEquipVisible: false,
      turnSingleVisible: false, //转单弹框是否显示
      dealWithVisible: false //处理弹框
    };
  },
  mixins: [listPage, listPageReszie, taskDetail, base],
  components: {
    DetailTableTitle,
    InstalledDevices,
    allUserSearch
  },
  props: ["serviceId", "corpId", "corpName", "basicInfo"],
  watch: {
    serviceId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getRepairService();
          this.getData();
        }
      }
    },
    // 操作时间
    "searchForm.updateTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.updateTime = this.searchForm.updateTime.reverse();
        }
        this.searchForm.updatedStartTime = val[0] || "";
        this.searchForm.updatedEndTime = val[1] || "";
      }
    },
    // 处理类型
    "handleForm.handleType": {
      immediate: true,
      handler(val) {
        if (val && this.$refs.handleForm) {
          this.$nextTick(() => {
            this.$refs.handleForm.clearValidate();
          });
        }
      }
    }
  },
  computed: {
    handleFormRules() {
      let rules = {
        quesReason: [
          {
            required: true,
            message: "请输入诊断问题",
            trigger: ["blur", "change"]
          }
        ]
      };
      if (this.handleForm.handleType == 1) {
        rules = {
          quesReason: [
            {
              required: true,
              message: "请输入诊断问题",
              trigger: ["blur", "change"]
            }
          ],
          solution: [
            {
              required: true,
              message: "请选择处理方案",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      if (this.handleForm.handleType == 2) {
        rules = {
          quesReason: [
            {
              required: true,
              message: "请输入诊断问题",
              trigger: ["blur", "change"]
            }
          ],
          isDept: [
            {
              required: true,
              message: "请选择是否指派部门",
              trigger: ["blur", "change"]
            }
          ],
          solveRpid: [
            {
              required: true,
              message: "请选择指派处理人",
              trigger: ["blur", "change"]
            }
          ],
          departmentId: [
            {
              required: true,
              message: "请选择指派部门",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return rules;
    }
  },
  methods: {
    //待处理状态才能进行处理
    checkSelectable(row) {
      return row.taskItemStatusCode == 1;
    },
    //查询维修服务单详情任务项分页列表
    async getRepairService() {
      try {
        let res = await getRepairService({
          serviceId: this.serviceId,
          ...this.searchForm
        });
        let tableData = res.data.array;
        for (let i = 0; i < tableData.length; i++) {
          if (i == 0) {
            tableData[i].show = true;
          } else {
            if (tableData[i].sceneName == tableData[i - 1].sceneName) {
              tableData[i].show = false;
            } else {
              tableData[i].show = true;
            }
          }
        }
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "查看已安装设备",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.showEquipVisible = true;
        this.vehicleId = row.vehicleId;
        this.sceneName = row.sceneName;
        this.sceneTypeName = row.sceneType;
      }
    },
    change() {
      this.$nextTick(() => {
        this.$refs.handleForm.clearValidate();
      });
    },
    //查看已安装设备弹框关闭
    closeDialog() {
      this.showEquipVisible = false;
    },
    //勾选任务项
    handleSelectionChange(val) {
      this.selColumn = val;
    },
    /*****处理**********/
    //点击处理
    handleDeal() {
      if (!this.selColumn.length) {
        this.$message.warning("请先选择数据");
        return;
      }
      this.dealWithVisible = true;
    },
    //确定处理
    confirmHandle() {
      this.$refs.handleForm.validate(valid => {
        if (valid) {
          if (this.handleForm.handleType == 3) {
            this.toCreateTask();
          } else {
            this.handleRepairServiceReq();
          }
        }
      });
    },
    //处理请求
    async handleRepairServiceReq() {
      try {
        this.dialogLoading = true;
        let res = await handleRepairService({
          serviceId: this.serviceId,
          ...this.handleForm,
          solveRpid:
            this.handleForm.isDept == 1
              ? this.handleForm.departmentId[0]
              : this.handleForm.solveRpid,
          taskItemIds: this.selColumn.map(item => {
            return item.id;
          })
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.handleClose();
          this.getRepairService();
          eventBus.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击转单---创建维修单
    toCreateTask() {
      // let queryData = JSON.stringify(this.basicInfo);
      let ids = this.selColumn.map(item => {
        let obj = {
          equipId: item.equipId,
          id: item.id
        };
        return obj;
      });
      let equipList = JSON.stringify(ids);
      this.routerPush({
        name: "taskListManage/addRepairOrder",
        params: {
          refresh: true,
          equipList,
          serviceId: this.basicInfo.serviceId,
          quesReason: this.handleForm.quesReason,
          queryType: 1
        }
      });
      this.handleClose();
    },
    //关闭处理弹框
    handleClose() {
      this.handleForm = {
        quesReason: "", //诊断问题
        handleType: 1, //处理方式
        solution: "", //处理方案
        remark: "", //备注
        solveRpid: "", //指派处理人
        departmentId: [], //指派部门
        isDept: "", //是否是部门
        transferType: "" //要转的工单类型
      };
      if (this.$refs.handleForm) {
        this.$refs.handleForm.resetFields();
      }
      this.selColumn = [];
      this.$refs.multipleTable.clearSelection();
      this.dealWithVisible = false;
    }
  },
  created() {
    this.getDepartment();
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
.work-order {
  margin-right: 10px;
  .item {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid #eae9ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    & > img {
      width: 40px;
      height: 37px;
    }
  }
  .til {
    width: 70px;
    text-align: center;
    font-size: 14px;
    color: #676769;
    margin-top: 10px;
  }
}
</style>
